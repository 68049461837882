export default {
    name: 'Categories',

    components: {},
    data() {
        return {
            categories: [],
            selectedItem: 1,
            spinner: false
        }
    },
    beforeMount() {
        this.$store.dispatch('setSpinner', true);
    },
    async mounted() {
        try{
            await Promise.all([
                this.$store.dispatch("fetchAllCategories")
            ])
            this.categories = this.$store.getters.getAllCategories;
        } catch(error){
            console.log(error);
            alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
        } finally {
            this.$store.dispatch('setSpinner', false);
        }
    },
    computed: {
        
    },
    methods: {
        openBooks(id) {
            this.$router.push({
                name: "BooksByCategory",
                params: {
                    id: id
                }
            })
        }
    }
}